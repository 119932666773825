<template>
  <section class="capture-list">
  </section>
</template>

<script>
import NewTestSuiteForm from '@/views/monitoring/components/test-suite-builder/NewTestSuiteForm';
import Paging from '@/entity/Paging';
import { mapState } from 'vuex';
export default {
  name: 'MonitoringModule',
  components: {},
  data() {
    return { }
  },
  computed: { 
    ...mapState('monitoring', ['list']),
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
    empty() {
      try {
        return this.list.items.length === 0;
      } catch (error) {
        return true;
      }
    }},
  methods: {
    filter(paging) {
      this.fetch({ paging });
    },
    async fetch({ params, paging } = {}) {
      this.loading = true;
      await this.$store.dispatch('monitors/list', {
        params: {
          ...(params || this.filters)
        },
        paging: paging || new Paging(),
        config: {
          disablePreload: true
        }
      });
      this.loading = false;
    },
    openNewTestSuiteModal() {
      this.$vxSlideIn.open({
        data: {},
        config: {
          component: NewTestSuiteForm,
          title: 'New Test Suite'
        }
      });
    }
  },  
  async created() {
    const { query } = this.$route;
    if (query.datecaptured) {
      this.form.datecaptured = query.datecaptured;
    }

    await this.fetch();
  }
};
</script>